import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import '../styles/Classement.css';
import '../styles/Table.css';
import '../styles/Background.css';
import '../styles/CodeDeLaRoute.css';
import $ from 'jquery';

import { backgroundRandom } from '../utils/BackgroundRandom';

import img_background from '../images/stop.png';
import img_first_place from '../images/twingo_or.png';
import img_second_place from '../images/twingo_argent.png';
import img_third_place from '../images/twingo_bronze.png';

import {
    useGoogleReCaptcha
} from "react-google-recaptcha-v3"

const APIURL = 'https://api.etbreuvage.fr/code_de_la_route/liste'
const APIURL_POST = 'https://api.etbreuvage.fr/contenus/code_de_la_route'

const CodeDeLaRoute = () => {

    const[persons, setPersons] = useState(['']);
    const [showPopup, setShowPopup] = useState(false);
    const[errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const[pseudo, setPseudo] = useState('');
    const[linkVideo, setlinkVideo] = useState('');
    const[successMessage, setsuccessMessage] = useState(null);
    const[token, setToken] = useState();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const popupRef = useRef(null);
    const buttonRef = useRef(null);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
          }
          const token = await executeRecaptcha('codeDeLaRoute');
          setToken(token);
    }, [executeRecaptcha]);

    const submitHandler = (event) => {
        event.preventDefault();
        if(pseudo.trim() === '' || linkVideo.trim() === '') {
            setErrorMessage('Attention il manque des petites choses !');
            return;
        }
        setErrorMessage(null);
        setsuccessMessage(null);
        setLoading(true);
        handleReCaptchaVerify().then(function(){
            const requestConf = {
                headers: {
                    'recaptcha-response': token
                }
            }
            const requestBody = {
                pseudo: pseudo,
                link: linkVideo
            }
            axios.post(APIURL_POST, requestBody, requestConf).then((response) => {
                setsuccessMessage(response.data);
                setLoading(false);
                setPseudo('');
                setlinkVideo('');
            }).catch((error) => {
                console.log(error);
                if (error.response === undefined) {
                    setErrorMessage('Aucune idée de ce qu\'il se passe.')
                }
                else if (error.response.status === 400 || error.response.status === 409) {
                    setErrorMessage(error.response.data);
                } else {
                    setErrorMessage('Aucune idée de ce qu\'il se passe.')
                }
                setLoading(false);
            })
        })
    }

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    function getRequest() {
        setLoading(true);
        axios.get(APIURL).then((response) => {
            setLoading(false);
            setPersons(response.data);
        }).catch((error) => {
            if (error.response.status === 400) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Aucune idée de ce qu\'il se passe.')
            }
            setLoading(false);
        })
    }

    function onSearch(e) {
        var value = e.target.value.toLowerCase();
        $("table").find("tr").each(function(index) {
            if (index === 0) return;
            var if_td_has = false;
            $(this).find('td').each(function () {
                if_td_has = if_td_has || $(this).text().toLowerCase().indexOf(value)!== -1;
            });
            $(this).toggle(if_td_has);
        });
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setShowPopup(false);
                setPseudo('');
                setlinkVideo('');
                setErrorMessage(null);
                setsuccessMessage(null);
                setLoading(false);
            }
        };
        
        if (showPopup) {
            document.addEventListener("click", handleClickOutside);
        }
        
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showPopup]);

    useEffect(() => {
        var element = document.getElementById('background_animation_image');
        element.innerHTML = backgroundRandom(JSON.stringify({img_background}["img_background"]));
        getRequest();
        handleReCaptchaVerify();
        
    }, [handleReCaptchaVerify]);

  return (
    <div className="classement">
      <div id="background_animation_color" class="area" >
          <ul id="background_animation_image" class="circles">
          </ul>
      </div>
      <div className="button-container">
        <button className="center-button" ref={buttonRef} onClick={togglePopup}>Proposez une situation dangereuse à Gilles !</button>
      </div>
      {showPopup && (
        <div className="popup-code-overlay">
        <div ref={popupRef} className="popup-code">
          <p className="popup-title">Ça tombe bien, Gilles est moniteur.</p>
          <form onSubmit={submitHandler}>
            <input type="text" value={pseudo} placeholder="Pseudo" onChange={event => setPseudo(event.target.value)} className="input-field"/>
            <br />
            <input type="text" value={linkVideo} placeholder="https://youtube.com/" onChange={event => setlinkVideo(event.target.value)} className="input-field"/>
            <br />
            <button type="submit" className="popup-submit">Envoyer</button>
            {loading && (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            )}
            {errorMessage && <p>{errorMessage}</p>}
            {successMessage && <p>{successMessage}</p>}
          </form>
        </div>
      </div>
      )}
      <div className="search-bar">
        <label htmlFor="search-input">Les meilleurs conducteurs</label>
        <input type="text" placeholder="Recherche..." onChange={(e) => {onSearch(e)}}/>
      </div>
      <div className="table-container">
        <table className='leaderboard-results'>
            <thead>
            <tr>
                <th className="table-cell-small">Rank</th>
                <th>Conducteur</th>
                <th className="table-cell-small">Cylindrée</th>
            </tr>
            </thead>
            {loading && (
                <tbody>
                    <tr>
                        <td>
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        </td>
                        <td>
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        </td>
                        <td>
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            )}
            {!loading && (
                <tbody>
                    {persons.map((val,key) => {
                        return (
                            <tr>
                                {(key+1 === 1) &&
                                    <td><img alt='first_place_code_de_la_route' className="table-cell-small table-cell-image-code" src={img_first_place}/></td>
                                }
                                {(key+1 === 2) &&
                                    <td><img alt='second_place_code_de_la_route' className="table-cell-small table-cell-image-code" src={img_second_place}/></td>
                                }
                                {(key+1 === 3) &&
                                    <td><img alt='thist_place_code_de_la_route' className="table-cell-small table-cell-image-code" src={img_third_place}/></td>
                                }
                                {(key+1 > 3) &&
                                    <td>{key+1}</td>
                                }
                                <td>{val.player_name}</td>
                                <td>{val.score} L</td>
                            </tr>
                        )
                    })}
                </tbody>
            )}
            {errorMessage && <p>{errorMessage}</p>}
        </table>
      </div>
    </div>
  );
};

export default CodeDeLaRoute;
