import React, { useState, useEffect } from 'react'
import axios from 'axios';
import '../styles/Classement.css';
import '../styles/Table.css';
import '../styles/Background.css';
import $ from 'jquery';

import { backgroundRandom } from '../utils/BackgroundRandom';

import img_background from '../images/cartouche.png';

const APIURL = 'https://api.etbreuvage.fr/jeux_videos/liste'

const JeuxVideo = () => {

    const[errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const[games, setGames] = useState(['']);

    function getRequest() {
        setLoading(true);
        axios.get(APIURL).then((response) => {
            setLoading(false);
            setGames(response.data['results']);
        }).catch((error) => {
            if (error.response.status === 400) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Aucune idée de ce qu\'il se passe.')
            }
            setLoading(false);
        })
    }

    function onSearch(e) {
        var value = e.target.value.toLowerCase();
        $("table").find("tr").each(function(index) {
            if (index === 0) return;
            var if_td_has = false;
            $(this).find('td').each(function () {
                if_td_has = if_td_has || $(this).text().toLowerCase().indexOf(value)!== -1;
            });
            $(this).toggle(if_td_has);
        });
    }

    useEffect(() => {
        var element = document.getElementById('background_animation_image');
        element.innerHTML = backgroundRandom(JSON.stringify({img_background}["img_background"]));
        getRequest();
    }, []);

  return (
    <div className="classement">
      <div id="background_animation_color" class="area" >
          <ul id="background_animation_image" class="circles">
          </ul>
      </div>
      <div className="search-bar">
        <label htmlFor="search-input">Les jeux vidéo</label>
        <input type="text" placeholder="Recherche..." onChange={(e) => {onSearch(e)}}/>
      </div>
      <div className="table-container">
            <table className='leaderboard-results'>
                <thead>
                <tr>
                    <th className="table-cell-small">Jaquette</th>
                    <th>Nom</th>
                    <th>Développement</th>
                </tr>
                </thead>
                {loading && (
                    <tbody>
                        <tr>
                            <td>
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            </td>
                            <td>
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            </td>
                            <td>
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
                {!loading && (
                    <tbody>
                        {games.map((val) => {
                            return (
                                <tr>
                                    <td>
                                        <a href={val.Link} target="_blank" rel="noreferrer">
                                            <img src={val.Link_image} alt={val.Name} style={{ width: '80%' }}/>
                                        </a>
                                    </td>
                                    <td>{val.Game_name}</td>
                                    <td>{val.Developer}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                )}
                {errorMessage && <p>{errorMessage}</p>}
            </table>
        </div>
    </div>
  );
};

export default JeuxVideo;
