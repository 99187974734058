import React, {useState, useEffect} from 'react';
import '../styles/Home.css';

import img_valerie from '../images/valerie.png';
import img_gilles from '../images/gilles.png';
import img_x from '../images/x-gold64.png';
import img_twitch from '../images/twitch-gold64.png';
import img_discord from '../images/discord-64.png';
import img_youtube from '../images/youtube-64.png';

const Home = () => {
  
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleAccept = () => {
    setShowPopup(false);
  };

  function getSentence() {
    var list_sentence = [
        "un sundae au chocolat chaud, ça fond dans la poche.",
        "une soirée avec les fonds de poches, je ne me souviens pas toujours de tout.",
        "tiramisu à la vanille, c’est pas vraiment un tiramisu, mais c’est pas dégueulasse.",
        "une flaque d’eau, c’est désagréable quand t’es en chaussettes.",
        "le film Camping 2 avec les bonus, ça dure environ 2 heures. Sauf que dedans y’a pas Richard Anconina. Mais bon y’a Gilles c’est pas mal non plus.",
        "une boîte de conserve de cassoulet, tant que t’as pas ouvert t’es pas vraiment sur que s’en est.",
        "un bon plat de pâte, c’est pas mauvais.",
        "une citation de Pascal, on comprend pas toujours tout.",
        "la motte de beurre dans le fond du frigo, elle fond dans la bouche quand tu croques dedans.",
        "une purée de patates, sans les patates, c’est plus un cri de détresse.",
        "un grand verre de Tropico, tu le trouves que dans les Kermesses.",
        "Harry Potter 2, c’est pas le pire.",
        "le contrôle de Math de la semaine dernière, la réponse c’était douze.",
        "c’est comme la dernière tranche de pain de mie, tout le monde s’en branle.",
    ]
    return list_sentence[Math.floor(Math.random() * list_sentence.length)];
  };

  useEffect(() => {
    var sentence_build = document.getElementById('the_sentence');
    sentence_build.innerHTML = "EtBreuvage c’est comme "+getSentence();
  }, []);

  return (
    <div className="home">
      <div className="content-container">
        <p className="centered-text">
          EtBreuvage c’est une émission sur Twitch tous les samedis à 18h, avec des évènements entre deux.<br/><br/>
          C’est interactif comme jouer avec ton petit frère à la PS1 le matin de Noël. Sauf que là pas besoin de passer la manette. 
          <span class="chut"> Moi je joue Tiger Jackson.</span><br/><br/>
          EtBreuvage ça a été créé dimanche dernier par :<br/><br/>
        </p>
        <div className="image-container">
          <div className="image-item">
            <img className="rotating-image" src={img_valerie} alt="Valerie" />
            <p className="image-text">Valérie Fauve</p>
          </div>
          <div className="image-item">
            <img className="rotating-image-reverse" src={img_gilles} alt="Gilles" />
            <p className="image-text">Gilles Cylindre</p>
          </div>
        </div>
        <p id='the_sentence' className="bottom-text">
          EtBreuvage c’est comme un tapis roulant sans pile, c’est un ramassis de conneries.
        </p>
        <div className="button-container">
          <button className="custom-button" onClick={handleButtonClick}>
            Des questions ?
          </button>
          <div className={`popup ${showPopup ? 'show' : ''}`}>
            <p>M’en fous je suis juste un site internet</p>
            <div className="popup-button">
              <button onClick={handleAccept}>D'accord</button>
            </div>
          </div>
          <div className="image-group">
            <a href="https://twitch.tv/etbreuvage" rel="noopener noreferrer" target="_blank">
              <img alt='twitch' className='logo-social' src={img_twitch}/>
            </a>
            <a href="https://discord.gg/Q76d3gfYHF" rel="noopener noreferrer" target="_blank">
              <img alt='discord' className='logo-social' src={img_discord}/>
            </a>
            <a href="https://www.youtube.com/channel/UCYxMgGLzqTwDPhTUUmcQcVg" rel="noopener noreferrer" target="_blank">
              <img alt='youtube' className='logo-social' src={img_youtube}/>
            </a>
            <a href="https://x.com/EtBreuvage" rel="noopener noreferrer" target="_blank">
              <img alt='x' className='logo-social' src={img_x}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
