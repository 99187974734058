import React, { useEffect, useState } from 'react';
import { Carousel } from "react-bootstrap";
import axios from 'axios';
import '../styles/LeMusee.css';
import '../styles/Table.css';
import '../styles/Background.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { backgroundRandom } from '../utils/BackgroundRandom';

import img_background from '../images/carte-lutin.png';

const APIURL = 'https://api.etbreuvage.fr/le_musee/liste'

const LeMusee = () => {
    
  const[oeuvres, setOeuvres] = useState(['']);
  const[errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  function getRequest() {
      setLoading(true);
      axios.get(APIURL).then((response) => {
          setLoading(false);
          setOeuvres(response.data['results']);
      }).catch((error) => {
          if (error.response.status === 400) {
              setErrorMessage(error.response.data.message);
          } else {
              setErrorMessage('Aucune idée de ce qu\'il se passe.')
          }
          setLoading(false);
      })
  }

  useEffect(() => {
      var element = document.getElementById('background_animation_image');
      element.innerHTML = backgroundRandom(JSON.stringify({img_background}["img_background"]));
      getRequest();
  }, []);

  return (
    <div className="le_musee">
      <div id="background_animation_color" class="area" >
          <ul id="background_animation_image" class="circles">
          </ul>
      </div>
      <div class="d-flex justify-content-center carousel">
        <div class="d-block w-50 carousel" data-ride="carousel">
            {loading && (
                <p className="spinner"></p>
            )}
            {!loading && (
                <Carousel interval={5000}>
                    {oeuvres.map((val) => {
                        return (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={val.Link}
                                    alt="First slide"
                                />
                                <Carousel.Caption className="custom-caption">
                                <p className="caption-text"><em>« {val.Description} »</em></p>
                                <br></br>
                                <p className="caption-text"><em>{(new Date(val.Date_publish)).toLocaleDateString('fr-FR')}</em></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            )}
        </div>
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default LeMusee;
