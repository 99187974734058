import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import {
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3"
import './styles/App.css';

import OubliePasLesEndives from './pages/OubliePasLesEndives';
import CodeDeLaRoute from './pages/CodeDeLaRoute';
import LeCinoche from './pages/LeCinoche';
import LeMusee from './pages/LeMusee';
import Home from './pages/Home';
import LesCommandes from './pages/LesCommandes';
import JeuxVideo from './pages/JeuxVideo';

import logo from './images/etbreuvage-logo-2.png';
import icon_endive from './images/endive128.png';
import icon_musee from './images/musee128.png';
import icon_cinoche from './images/cinoche128.png';
import icon_code from './images/code128.png';
import icon_jeux from './images/manette128.png';
import icon_mains from './images/mains128.png';

class App extends React.Component {
  render() {
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6LetvQwiAAAAAC81G743ClPpYQe_4o3DeuxhyeBN">
        <BrowserRouter>
          <div className="app">
            <div className="menu">
              <Link to="/"><img src={logo} alt="EtBreuvage" /></Link>
              <Link to="/oublie_pas_les_endives"><img src={icon_endive} alt="Endive" /></Link>
              <Link to="/code_de_la_route"><img src={icon_code} alt="Code" /></Link>
              <Link to="/le_cinoche"><img src={icon_cinoche} alt="Cinoche" /></Link>
              <Link to="/le_musee"><img src={icon_musee} alt="Musee" /></Link>
              <Link to="/jeux_videos"><img src={icon_jeux} alt="JeuxVideo" /></Link>
              <Link to="/les_commandes"><img src={icon_mains} alt="Commandes" /></Link>
            </div>
            <div className="content">
              <Routes>
                <Route exact path="/" element={< Home />} />
                <Route exact path="/oublie_pas_les_endives" element={< OubliePasLesEndives />} />
                <Route exact path="/code_de_la_route" element={< CodeDeLaRoute />} />
                <Route exact path="/le_cinoche" element={< LeCinoche />} />
                <Route exact path="/le_musee" element={< LeMusee />} />
                <Route exact path="/jeux_videos" element={< JeuxVideo />} />
                <Route exact path="/les_commandes" element={< LesCommandes />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    );
  }
}

export default App;
