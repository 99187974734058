import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Classement.css';
import '../styles/Table.css';
import '../styles/Background.css';
import $ from 'jquery';

import { backgroundRandom } from '../utils/BackgroundRandom';

import img_background from '../images/endive.png';
import img_first_place from '../images/endive_or.png';
import img_second_place from '../images/endive_argent.png';
import img_third_place from '../images/endive_bronze.png';

const APIURL = 'https://api.etbreuvage.fr/oublie_pas_les_endives/liste'

const OubliePasLesEndives = () => {

    const[persons, setPersons] = useState(['']);
    const[errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    function getRequest() {
        setLoading(true);
        axios.get(APIURL).then((response) => {
            setLoading(false);
            setPersons(response.data);
        }).catch((error) => {
            if (error.response.status === 400) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Aucune idée de ce qu\'il se passe.')
            }
            setLoading(false);
        })
    }

    function onSearch(e) {
        var value = e.target.value.toLowerCase();
        $("table").find("tr").each(function(index) {
            if (index === 0) return;
            var if_td_has = false;
            $(this).find('td').each(function () {
                if_td_has = if_td_has || $(this).text().toLowerCase().indexOf(value)!== -1;
            });
            $(this).toggle(if_td_has);
        });
    }

    useEffect(() => {
        var element = document.getElementById('background_animation_image');
        element.innerHTML = backgroundRandom(JSON.stringify({img_background}["img_background"]));
        getRequest();
    }, []);

  return (
    <div className="classement">
        <div id="background_animation_color" class="area" >
            <ul id="background_animation_image" class="circles">
            </ul>
        </div>
        <div className="search-bar">
        <label htmlFor="search-input">Les meilleures endives</label>
        <input type="text" placeholder="Recherche..." onChange={(e) => {onSearch(e)}}/>
        </div>
        <div className="table-container">
            <table className='leaderboard-results'>
                <thead>
                <tr>
                    <th className="table-cell-small">Rank</th>
                    <th>Endive du mois</th>
                    <th className="table-cell-small">Calibre</th>
                </tr>
                </thead>
                {loading && (
                    <tbody>
                        <tr>
                            <td>
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            </td>
                            <td>
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            </td>
                            <td>
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
                {!loading && (
                    <tbody>
                        {persons.map((val,key) => {
                            return (
                                <tr>
                                    {(key+1 === 1) &&
                                        <td><img alt='first_place_endive' className="table-cell-small table-cell-image-endive" src={img_first_place}/></td>
                                    }
                                    {(key+1 === 2) &&
                                        <td><img alt='second_place_endive' className="table-cell-small table-cell-image-endive" src={img_second_place}/></td>
                                    }
                                    {(key+1 === 3) &&
                                        <td><img alt='thist_place_endive' className="table-cell-small table-cell-image-endive" src={img_third_place}/></td>
                                    }
                                    {(key+1 > 3) &&
                                        <td>{key+1}</td>
                                    }
                                    <td>{val.player_name}</td>
                                    <td>{val.score}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                )}
                {errorMessage && <p>{errorMessage}</p>}
            </table>
        </div>
    </div>
  );
};

export default OubliePasLesEndives;
