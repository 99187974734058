import React, { useEffect } from 'react'
import '../styles/Classement.css';
import '../styles/Table.css';
import '../styles/Background.css';
import $ from 'jquery';

import { backgroundRandom } from '../utils/BackgroundRandom';

import img_background from '../images/soucoupe.png';

const LesCommandes = () => {

    const data = {
        command1: { command: '!aboire', description: 'Offre un délicieux breuvage.' },
        command2: { command: '!raton ou !raccoon', description: 'Fait passer un raton sur le bar.' },
        command3: { command: '!popcorn', description: 'Fait tomber un popcorn.' },
        command4: { command: '!cartelutin', description: 'Fait tomber une carte lutin.' },
        command5: { command: '!gilles', description: 'Fait tomber Gilles.' },
        command6: { command: '!valerie', description: 'Fait tomber Valérie.' },
        command7: { command: '!chat', description: 'Fait tomber un chat.' },
        command8: { command: '!twingo', description: 'Fait tomber une Twingo.' },
        command9: { command: '!pancake', description: 'Fait tomber Pancake.' },
        command10: { command: '!connard ou !connards', description: 'Fait tomber deux connards.' },
        command11: { command: '!yaourt', description: 'Fait tomber un yaourt.' },
        command12: { command: '!toast', description: 'Fait tomber un toast.' },
        command13: { command: '!quisuisje', description: 'Permets de savoir qui tu es.' },
        command14: { command: '!discord', description: 'Donne le lien du Discord : breuvage.fr/discord.' },
        command15: { command: '!youtube', description: 'Donne le lien du YouTube : breuvage.fr/youtube.' },
        command16: { command: '!twitter', description: 'Donne le lien du X (Twitter) : breuvage.fr/x.' },
        command17: { command: '!tututu', description: 'Donne le lien de la playlist qui fait tututu : breuvage.fr/tututu.' },
        command18: { command: '!nalana', description: 'Donne le lien de la playlist qui fait nanana ou lalala : breuvage.fr/nalana.' },
    };

    function onSearch(e) {
        var value = e.target.value.toLowerCase();
        $("table").find("tr").each(function(index) {
            if (index === 0) return;
            var if_td_has = false;
            $(this).find('td').each(function () {
                if_td_has = if_td_has || $(this).text().toLowerCase().indexOf(value)!== -1;
            });
            $(this).toggle(if_td_has);
        });
    }

    useEffect(() => {
        var element = document.getElementById('background_animation_image');
        element.innerHTML = backgroundRandom(JSON.stringify({img_background}["img_background"]));
    }, []);

  return (
    <div className="classement">
      <div id="background_animation_color" class="area" >
          <ul id="background_animation_image" class="circles">
          </ul>
      </div>
      <div className="search-bar">
        <label htmlFor="search-input">Les commandes de la Ste EtBreuvage</label>
        <input type="text" placeholder="Recherche..." onChange={(e) => {onSearch(e)}}/>
      </div>
      <div className="table-container">
            <table className='leaderboard-results'>
                <thead>
                <tr>
                    <th className="table-cell-small">Commande</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(data).map((key) => (
                    <tr key={key}>
                        <td>{data[key].command}</td>
                        <td>{data[key].description}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    </div>
  );
};

export default LesCommandes;